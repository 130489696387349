<template>
  <div id="app">

    <div class="step1">
      <div class="txb2" style="margin:0">

        <div class="bank_sub_title">
          <span><font class=""></font>第一步 输入身份证号</span>
        </div>

        <div class="txb2">
          <van-field
              v-model="info.certNum"
              required
              maxlength="18"
              label="身份证号码"
              placeholder="请输入身份证号码"
          />
        </div>

        <div class="bank_sub_title">
          <span><font class=""></font>第二步 上传身份证</span>
        </div>

        <div class="bank_sub_title">
          <span>请点击上传身份证</span>
          <label>拍照时请确保身份证边框完整、图像清晰、光线均匀。</label>
        </div>
        <div class="bank_upload clear">
          <ul class="clear">
            <li>
              <van-uploader v-model="idcardList2" name="idcard2" accept="image/*"
                            :after-read="uploadIdCardToBank" :max-count="1"/>
              <span>请上传身份证人像面</span>
            </li>
            <li>
              <van-uploader v-model="idcardList1" name="idcard1" accept="image/*"
                            :after-read="uploadIdCardToBank" :max-count="1"/>
              <span>请上传身份证国徽面</span>
            </li>
          </ul>
          <div class="bank_upload_b clear">
            <img src="../../../src/assets/images/photo_upload_b.jpg" alt=""/>
          </div>
        </div>
      </div>

    </div>

    <div class="bank_btn">
      <button @click="resubmit">
        提交
      </button>
    </div>

    <div class="loading_all" v-if="loading">
      <van-loading type="spinner" color="#ffffff" vertical>上传中...</van-loading>
    </div>
  </div>
</template>


<style scoped>
#app {
  background: #fff;
  height: 100vh;
  overflow: scroll;
  position: relative;
  z-index: 2000;
}


::v-deep .van-uploader__upload {
  margin: 0 !important;
  width: 40vw !important;
  height: 23vw !important;
  background: url(../../../src/assets/images/photo_upload.png) center center;
  background-size: 100% 100%;
}

::v-deep .bank_upload ul li:nth-child(2) .van-uploader__upload {
  margin: 0 !important;
  width: 40vw !important;
  height: 23vw !important;
  background: url(../../../src/assets/images/photo_upload2.png) center center;
  background-size: 100% 100%;
}

::v-deep .van-uploader__preview {
  margin: 0 !important;
}

::v-deep .van-uploader__preview-image {
  width: 40vw !important;
  height: 23vw !important;
  overflow: hidden;
}

::v-deep .van-uploader__upload-icon {
  display: none;
}

/* ::v-deep .van-field__label {
    width: 9em !important;
} */

::v-deep .van-cell__value {
  /* text-align: left !important; */
}

</style>

<script>
import Vue from 'vue'
import {post} from "@/utils/http";
import Notify from "vant/es/notify";
import {Dialog} from 'vant';

export default {
  data() {
    return {
      loading: false,
      info: {
        certNum: "",
        imageDataNo: "",
        merchantNum: "",
        paymentPlatformId: "73d1c2341636435f8e364f9db552a277", // 广发写死
      },
      img1: '',
      img2: '',
      idcardList1: [],
      idcardList2: [],

    };
  },
  mounted() {

  },
  methods: {
    uploadIdCardToBank(file, name) {
      if (!this.info.certNum) {
        Notify({type: "danger", message: '请输入身份证号后再进行上传操作'});

        if (name.name == 'idcard1') {
          this.idcardList1 = [];
          this.img1 = '';
        } else if (name.name == 'idcard2') {
          this.idcardList2 = [];
          this.img2 = '';
        }

        return false;
      }

      let that = this
      let func = function () {
        //判断是否已选择两张图片
        if (that.img1 && that.img2) {
          let content1 = that.img1.replace(/^data:image\/\w+;base64,/, '');
          let content2 = that.img2.replace(/^data:image\/\w+;base64,/, '');

          that.loading = true;
          post('/api/guangfa/v2/uploadCustImageData', {
            certNum: that.info.certNum,
            obverse: content1,
            reverse: content2
          }).then(r => {
            that.info.imageDataNo = r.data.imageDataNo;
            that.info.merchantNum = r.data.merchantNum;
            Dialog.alert({
              confirmButtonText: '我知道了',
              title: '温馨提示',
              message: '根据银行规定，身份证影像需经银行人工审核后，才能开通电子钱包进行充值，敬请留意审核通知短信'
            }).then(() => {
              that.loading = false;
            });
            that.loading = false;
          }).catch(err => {
            that.loading = false;

            Notify({type: "danger", message: err.message});

            that.idcardList1 = [];
            that.img1 = '';
            that.idcardList2 = [];
            that.img2 = '';

          })
        }
      }

      if (name.name == 'idcard1') {
        this.dealImage(file.content, 700, (base64) => {
          that.img1 = base64;
          func();
        });
      } else if (name.name == 'idcard2') {
        this.dealImage(file.content, 700, (base64) => {
          that.img2 = base64;
          func();
        });
      }
    },
    resubmit() {
      this.loading = true;
      post('/api/guangfa/v2/modifyCustImageDate', {
        certNo: this.info.certNum,
        yxSatus: "04",
        isOCR: 0,
        yxNO: this.info.imageDataNo
      }).then(r => {
        this.loading = false;

        this.$router.replace({path: '/AccountCreateFeedback', query: {type: 'ID_CARD_REVIEWING'}})

      }).catch(err => {
        this.loading = false;

        Notify({type: "danger", message: JSON.stringify(err)});

      })

    },
    stringTrim(str) {
      if (str.trim) return str.trim()
      return str;
    },
    cancel() {
      this.$router.back(-1);
    },
    //压缩base64图片
    dealImage(base64, w, callback) {
      var newImage = new Image();
      var quality = 0.9;    //压缩系数0-1之间
      newImage.src = base64;
      newImage.setAttribute("crossOrigin", 'Anonymous');	//url为外域时需要
      var imgWidth, imgHeight;
      newImage.onload = function () {
        imgWidth = this.width;
        imgHeight = this.height;
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        if (Math.max(imgWidth, imgHeight) > w) {
          if (imgWidth > imgHeight) {
            canvas.width = w;
            canvas.height = w * imgHeight / imgWidth;
          } else {
            canvas.height = w;
            canvas.width = w * imgWidth / imgHeight;
          }
        } else {
          canvas.width = imgWidth;
          canvas.height = imgHeight;
          quality = 0.6;
        }
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(this, 0, 0, canvas.width, canvas.height);
        var base64 = canvas.toDataURL("image/jpeg", quality); //压缩语句
        // 如想确保图片压缩到自己想要的尺寸,如要求在50-150kb之间，请加以下语句，quality初始值根据情况自定
        // while (base64.length / 1024 > 150) {
        // 	quality -= 0.01;
        // 	base64 = canvas.toDataURL("image/jpeg", quality);
        // }
        // 防止最后一次压缩低于最低尺寸，只要quality递减合理，无需考虑
        // while (base64.length / 1024 < 50) {
        // 	quality += 0.001;
        // 	base64 = canvas.toDataURL("image/jpeg", quality);
        // }
        callback(base64);//必须通过回调函数返回，否则无法及时拿到该值
      }
    }
  }
}
;
</script>
